import { useState, useEffect } from "react";
import Axios from "axios";


// State Global Dengan Contex
import { GlobalStateConsumer } from "../state.global";
import Login from "../components/login";
import LoginRegistrasiAccount from "../components/loginRegistrasiAccount";
import CompLoading from "../component/arjLoading";
import Env from "../setting/env";

import PagesGenkiFoundation from "./genkiFoundation.pages";
import PageOrganisations from "./organisation.pages";

import ImgAng from "../assets/image/ang.png";
import ImgGenki from "../assets/image/genki.ico";


function Pages(props) {
	const [sLoading, setSLoading] = useState(true);
	const [sAccount, setSAccount] = useState("");
	const [sBorderColor, setsBorderColor] = useState({
		eLogin: "",
		eRegister: "",
	});
	const [sOrganisasi, setSOrganisasi] = useState([Env.eGenkiFoundationPage]);
	const [sOrganisasiIndex, setSOrganisasiIndex] = useState(0);
	const [sPages, setsPages] = useState(Env.eGenkiFoundationPage);

	const fLayarSize = () => {
		if (window.innerWidth <= 768) {
			return "sm";
		} else if (window.innerWidth > 768 && window.innerWidth < 1300) {
			// 1024
			return "md";
		} else {
			return "xl";
		}
	};

	useEffect(() => {
		props.dispatch({ type: "layarSize", data: fLayarSize() });
		window.addEventListener("resize", () => {
			props.dispatch({ type: "layarSize", data: fLayarSize() });
		});
		// return () => {
		// 	window.removeEventListener("resize");
		// };
		
		// console.log(`${sPages.url}/organisasi`);
		Axios.get(`${sPages.url}/organisasi`).then((xData) => {
			setSOrganisasi((xState) => {
				// const xDataLength = xData.data.message.length
				// console.log(xDataLength);
				let iState = [
					{ nama: xState[0].nama, url: xState[0].url },
					...xData.data.message,
					
					
				];

				return iState;
			});
			setSLoading(false);
		});
		// eslint-disable-next-line
	}, []);

	if (props.state.sPages.nama === "") {
		return (
			<div className="overflow-hidden">
				{sLoading ? <CompLoading /> : ""}

				{/* <div className="flex z-100 fixed w-screen h-16 pt-2 md:h-11 md:pt-0"> */}
				<nav className="Nav fixed z-100 md:p-1 overflow-x-auto">
					<div className="flex flex-col md:flex-row flex-1 justify-center items-center space-x-1">
						<h2>Silahkan Pilih Organisasi</h2>
						<select
							className="Button"
							value={sOrganisasiIndex}
							onChange={(xValue) => {
								let iValue = parseInt(xValue.nativeEvent.target.value);
								let iPages = sOrganisasi[iValue];
								setsPages(iPages);
								setSOrganisasiIndex(iValue);
							}}
						>
							{sOrganisasi.map((xValue, xIndex) => {
								return (
									<option value={xIndex} key={xIndex}>
										{xValue.nama}
									</option>
								);
							})}
						</select>
					</div>

					<div className="flex text-base items-end space-x-1 ml-1 ">
						<button
							className="Button h-8"
							style={{ width: "70px", borderColor: sBorderColor.eLogin }}
							onClick={() => {
								setSAccount("login");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eLogin = Env.color.orangeRed03;
									iState.eRegister = "";
									return iState;
								});
							}}
						>
							Login
						</button>
						<button
							className="Button h-8"
							style={{ width: "90px", borderColor: sBorderColor.eRegister }}
							onClick={() => {
								setSAccount("Registrasi");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eLogin = "";
									iState.eRegister = Env.color.orangeRed03;
									return iState;
								});
							}}
						>
							Registrasi
						</button>
					</div>
				</nav>
				{/* </div> */}

				<div className="relative w-full ">
					<div className=" flex flex-col justify-center items-center p-2 w-full absolute">
						<img
							className=" mt-24  w-20 h-20 rounded-xl border-solid border-2 border-black"
							src={ImgAng}
							alt=""
						/>
						<div
							className=" h-auto w-auto p-4 m-4 flex flex-col space-y-2
								rounded-2xl border-2 border-solid justify-center items-center"
							style={{ background: "rgba(9,9,9,80%)" }}
						>
							<p className="text-xl md:text-2xl text-red-500 text-center">
								"Om Awignam Astu Namo Sidham"
							</p>
							<p className="text-xl md:text-2xl text-red-500 text-center">This Web</p>
							<p className="text-xl md:text-2xl text-red-500 text-center">
								For Social Commonity Only
							</p>
							<p className="text-2xl md:text-3xl text-red-600 text-center">
								Powered by: Warung Genki
							</p>
						</div>
					</div>
					<div className=" h-screen w-full pt-20 px-4 pb-4 md:p-20">
						<img
							className="h-full w-full   opacity-25 rounded-xl border-solid border-4 border-black"
							src={ImgGenki}
							alt=""
						/>
					</div>

					{sAccount === "login" ? (
						<Login
							_close={() => {
								setSAccount("");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eLogin = "";
									return iState;
								});
							}}
							_pages={sPages}
						/>
					) : sAccount === "Registrasi" ? (
						<LoginRegistrasiAccount
							_close={() => {
								setSAccount("");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eRegister = "";
									return iState;
								});
							}}
							_pages={sPages}
						/>
					) : (
						""
					)}
				</div>
			</div>
		);
	} else if (props.state.sPages.nama === Env.eGenkiFoundationPage.nama) {
		return (
			<PagesGenkiFoundation
				_onLogOut={() => {
					setsPages(Env.eGenkiFoundationPage);
				}}
			/>
		);
	} else {
		return (
			<PageOrganisations
				_onLogOut={() => {
					setsPages(sPages);
				}}
			/>
		);
	}
}

export default GlobalStateConsumer(Pages);
